.admin-dashboard-container {
  padding: 40px;
  background-color: #f4f4f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 40px;
}

.logout-button {
  background-color: #6d8d23;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.current-user {
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
}

.dashboard-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
}

.dashboard-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 300px;
  text-align: left;
}

.dashboard-card h2 {
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
}

.dashboard-card form {
  display: flex;
  flex-direction: column;
}

.dashboard-card input, .dashboard-card select {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.create-button, .update-button, .delete-button {
  background-color: #6d8d23;
  color: white;
  padding: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.create-button:hover, .update-button:hover, .delete-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.delete-button {
  background-color: rgb(234, 51, 35, 1.0);
}

.delete-button:hover {
  background-color: rgb(200, 51, 35, 1.0);
}

.manage-card ul {
  list-style: none;
  padding: 0;
}

.manage-card ul li {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.manage-card ul li p {
  margin: 5px 0;
}

.remove-roomtype-button {
  margin-bottom: 20px;
  background-color: rgb(234, 51, 35, 1.0);
  font-size: 70%;
}
.remove-roomtype-button:hover {
  background-color: rgb(200, 51, 35, 1.0);
}

.add-roomtype-button {
  margin-bottom: 20px;
  font-size: 70%;
}

.error-message {
  color: red;
  font-weight: 600;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
}

.modal-content li {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.modal-content p {
  margin: 5px 0;
}

.close-button {
  background-color: #6d8d23;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.toggle-view-button {
  background-color: #6d8d23;
  color: white;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-view-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.select-winners-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.select-winners-button {
  background-color: #6d8d23;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.select-winners-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.select-winners-card ul {
  list-style: none;
  padding: 0;
}

.select-winners-card ul li {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.select-winners-card ul li p {
  margin: 5px 0;
}
