/* General Styles */
body, html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  background-color: #f4f4f9;
  text-align: center;
}

.logo {
    height: 90px;
    margin-top: 1vh;
}

.auth-container {
  margin: 0 auto;
  padding-top: 10vh;
  width: 100%;
  max-width: 400px;
}

.auth-card {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  font-weight: 500;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #6d8d23;
}

.auth-button {
  width: 100%;
  background-color: #6d8d23;
  color: white;
  padding: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.auth-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.error-message {
  color: #ff4d4d;
  margin-bottom: 20px;
}

.auth-link {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

.auth-link a {
  color: #6d8d23;
  text-decoration: none;
  font-weight: 600;
}

.auth-link a:hover {
  color: #8ba749;
}

.show-password-toggle {
  background: none;
  border: none;
  color: #6d8d23;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
  padding: 0;
  font-weight: 600;
}