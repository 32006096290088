/* Scoped for User Dashboard */
.dashboard-container {
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: auto;
  margin-top: 10vh;
}

.dashboard-container h1 {
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

.dashboard-container .user-info {
  text-align: left;
  margin-bottom: 30px;
}

.dashboard-container .user-info p {
  font-size: 16px;
  margin: 10px 0;
  color: #555;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

button {
  background-color: #6d8d23;
  color: white;
  padding: 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
  margin-left: 10px;
}

button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

.lottery-details p {
  margin-bottom: 4px;
  margin-top: 4px;
}

.error-message {
  color: red;
  font-weight: 600;
}

.dashboard-container .logout-button {
  background-color: #6d8d23;
  color: white;
  padding: 12px 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dashboard-container .logout-button:hover {
  background-color: #7da335;
  transform: translateY(-2px);
}

/* Modal and Form Styling */
.modal-content select, 
.modal-content input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
}

.modal-content button {
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.modal-content label {
  font-weight: 550;
  margin-bottom: 10px;
}

.modal-content button:hover {
  background-color: #6d8d23;
  color: white;
}

.modal-content a {
  margin-bottom: 20px;
}

.warning-message {
  color: red;
  font-weight: 600;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
}
